import { Suspense } from "react";
import { Footer } from "../Footer";
import Header from "../Header";
import "./index.style.css";

const RootLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <main className="layout">{children}</main>
      </Suspense>
      <Footer />
    </div>
  );
};

export { RootLayout };
