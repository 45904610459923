import React from "react";
import "./index.style.css";

const Banner = () => {
  return (
    <section id="banner" className="flex items-center justify-center">
      <div className="text block-banner-text h-full flex flex-col justify-end">
        <h1 className="baner-text banner-title pt-5 pl-5 pr-5">
          Centre Esthétique Basilix
        </h1>
        <p className="baner-text text-start pl-5 pr-5">wellness clinic</p>
      </div>
      <div>
        <a className="banner-custom-btn ml-5 mr-5" href="#form">
          PRENDRE RENDEZ-VOUS
        </a>
        <p className="baner-text baner-description text-md sm:text-2xl lg:text-4xl mt-2 lg:mt-5 text-start pt-2 pr-5 pl-5 tb-5">
          Centre Esthétique Basilix accorde une attention particuliére aux
          besoins féminins de soins et beauté
        </p>
      </div>
    </section>
  );
};

export { Banner };
